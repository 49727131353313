// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fallback-spinner {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 3px solid transparent;
}`, "",{"version":3,"sources":["webpack://./src/layouts/loader/loader.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;AACF;;AACA;EACE,kBAAA;EACA,sBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EAEA,sBAAA;EACA,6BAAA;AAEF","sourcesContent":[".fallback-spinner {\n  position: relative;\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n.loading {\n  position: absolute;\n  left: calc(50% - 35px);\n  top: 50%;\n  width: 55px;\n  height: 55px;\n  border-radius: 50%;\n  -webkit-box-sizing: border-box;\n  box-sizing: border-box;\n  border: 3px solid transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
