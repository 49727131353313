import axios from "axios";

const interactionService = {
  async getListSave(token) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/interaction/list/save`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch saved list", error);
      throw new Error("Failed to fetch saved list");
    }
  },

  async getListApply(token) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/interaction/list/apply`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch applied list", error);
      throw new Error("Failed to fetch applied list");
    }
  },

  async checkSaveStatus(token, jobID) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/interaction/save`,
        {
          params: { jobID },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch applied list", error);
      throw new Error("Failed to fetch applied list");
    }
  },

  async updateSave(token, jobID, matchID, save) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/interaction/save`,
        { jobID, matchID, save },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to update save status", error);
      throw new Error("Failed to update save status");
    }
  },

  async updateApply(token, jobID, matchID) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/interaction/apply`,
        { jobID, matchID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to update apply status", error);
      throw new Error("Failed to update apply status");
    }
  },
};

export default interactionService;
