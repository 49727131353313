import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearch } from "./SearchProvider";
import Loader from "../layouts/loader/Loader";

const PrivateRoute = ({ children, authorize }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { preferences, loading } = useSearch();

  if (isLoading || loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  // if (authorize && !authorize(preferences)) {
  //   console.log(authorize(preferences));
  //   return <Navigate to="/app/starter" />;
  // }

  if (authorize) {
    const isAuthorized = authorize(preferences);
    if (isAuthorized === undefined) {
      return <Navigate to="/preferences" />;
    } else if (!authorize(preferences)) {
      return <Navigate to="/app/starter" />;
    }
  }

  return children;
};

export default PrivateRoute;
