import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const AuthRoute = ({ element }) => {
  const { isAuthenticated } = useAuth0();

  // Redirect if the user is already authenticated and trying to access root
  return isAuthenticated ? <Navigate to="/app/starter" /> : element;
};
export default AuthRoute;
