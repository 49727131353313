// import { useCallback } from "react";
import axios from "axios";
// const API_BASE_URL = "http://127.0.0.1:5000";

const JobService = {
  async getJobList(token) {
    try {
      //   console.log(token);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/job/list?limit=300`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //   console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching job list:", error);
      throw error;
    }
  },

  async getJobDetail(token, uuid) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/job/detail/${uuid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching job detail:", error);
      throw error;
    }
  },
};

export default JobService;
