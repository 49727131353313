import axios from "axios";

const matchService = {
  async getMatchList(token) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/match/list?limit=300`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch match list", error);
      throw new Error("Failed to fetch match list");
    }
  },
  async getMatchListToday(token) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/match/today`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch today's match list", error);
      throw new Error("Failed to fetch today's match list");
    }
  },
  async updateMatch(token, matchData) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/match/update`,
        matchData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      //   console.log(matchData);
      return response.data;
    } catch (error) {
      console.error("Failed to update match", error);
      throw new Error("Failed to update match");
    }
  },
  // async trackClick(token, clickData) {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_BASE_URL}/match/click`,
  //       clickData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("Failed to track click", error);
  //     throw new Error("Failed to track click");
  //   }
  // },
};

export default matchService;
