import React, { Suspense } from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./assets/scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./routes/Auth0ProviderWithNavigate";
import Loader from "./layouts/loader/Loader";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  // // <React.StrictMode>
  // <HashRouter>
  //   <Auth0ProviderWithNavigate>
  //     <App />
  //   </Auth0ProviderWithNavigate>
  // </HashRouter>
  // // </React.StrictMode>
  // // document.getElementById("root")

  <Suspense fallback={<Loader />}>
    <HashRouter>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </HashRouter>
  </Suspense>
);

reportWebVitals();
