import axios from "axios";

const userService = {
  async getUser(token) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch user data");
    }
  },
  async updateUser(token, userData) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/user/update`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to update user");
    }
  },
  async createUser(token, userData) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/create`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to create preferences");
    }
  },
};

export default userService;
